import { useEffect, useState } from "react";
import RequestFeatureTable from "./RequestFeatureTable";
import requestFeatureService from "../../services/requestFeature";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({data}) => {
    return (
      <ExcelFile
        element={
          <button
            style={{ width: "max-content" ,marginLeft:'auto'}}
            className="otjs-button otjs-button-green"
          >
            Export/Download
          </button>
        }
      >
        <ExcelSheet data={data} name="Request-Feature">
          <ExcelColumn label="Patient Name" value="patient_name" />
          <ExcelColumn label="Patient ID" value="patient_id" />
          <ExcelColumn label="Hospital" value="hospital" />
          <ExcelColumn label="Request Date" value={"createdAt"} />
          <ExcelColumn label="Study Type" value={"study_type"} />
          <ExcelColumn label="Study Date" value={"study_date"} />
          <ExcelColumn label="Request Type" value={"request_type"} />
          <ExcelColumn label="Indication" value={"indication"} />
          <ExcelColumn label="Radiologist" value={"radiologist"} />
        </ExcelSheet>
      </ExcelFile>
    );
};
const RequestFeatureListPage = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    requestFeatureService.getList()
    .then(resJson=>setData(resJson))
    .catch(err=>console.log(err))
  },[])
  return (
    <>
      <div style={{width:'max-content'}} className="my-2 ms-auto">
        <ExportExcel data={data}/>
      </div>
      <RequestFeatureTable tableData={data} />
    </>
  );
};
export default RequestFeatureListPage;
